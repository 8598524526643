import { ChevronRightIcon } from '@heroicons/react/solid';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { sizeTheme, sectionStyle, iconTheme } from 'holded/lib/styles';
import { ErrorPageSection } from 'holded/modules/cms/domain/components/error-page';
import { Page } from 'holded/modules/cms/domain/page';
import { application } from 'holded/modules/cms/ui';
import HeroIcon from 'holded/modules/cms/ui/shared/components/HeroIcon';
import NextImage from 'holded/modules/cms/ui/shared/components/Image';

const THEME = {
  background: {
    light: sectionStyle.background.light,
  },
  headersTextColor: {
    light: sectionStyle.textColor.default,
  },
  textColor: {
    light: sectionStyle.textColor.secondary,
  },
  highlightTextColor: {
    light: sectionStyle.textColor.primary,
  },
  footerTextColor: {
    light: sectionStyle.textColor.grayMedium,
  },
};

type Props = {
  pageData: Page[];
  locales: string[];
  defaultLocale: string;
};

const ERROR_ICON_THEME = iconTheme.linear;
const SIZE_THEME = sizeTheme.small;

const getErrorSectionData = (page: Page | undefined): ErrorPageSection | null => {
  if (!page) return null;
  const data = page.attributes?.sections.find((section) => section.__component == 'error-page.404');

  if (!data) return null;

  return data as ErrorPageSection;
};

const ErrorPage = ({ pageData, locales, defaultLocale }: Props) => {
  const router = useRouter();
  const paramLocale = router.asPath.split('/')[1];
  const locale = locales.findIndex((item) => item == paramLocale) !== -1 ? paramLocale : defaultLocale;
  const dataLocale = pageData?.find((item) => item?.attributes?.locale == locale);
  const data = getErrorSectionData(dataLocale);

  const localePath = locale === defaultLocale ? '' : locale;

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.onload = () => {
      window.dataLayer?.push({ event: '404_page_not_found' });
    };
  }, []);

  return (
    <>
      {data && (
        <div className={`${THEME?.background?.light}`}>
          <main className="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex-shrink-0 pt-16">
              {data?.logo && <NextImage media={data.logo.data} classImg={'mx-auto h-12 w-auto'} />}
            </div>
            <div className="max-w-xl mx-auto py-16 sm:py-24">
              <div className="text-center">
                <p
                  className={`${THEME?.highlightTextColor?.light} ${SIZE_THEME} leading-5 font-semibold uppercase tracking-wide`}
                >
                  {data?.titleSecondary}
                </p>
                <h1 className={`${THEME?.headersTextColor?.light} mt-2 text-h2 font-extrabold tracking-tight`}>
                  {data?.title}
                </h1>
                <p className={`mt-2 text-b1 leading-7 ${THEME?.textColor?.light}`}>{data?.description}</p>
              </div>
              <div className="mt-12">
                <h2
                  className={`${THEME?.textColor?.light} leading-5 ${SIZE_THEME} font-semibold tracking-wide uppercase`}
                >
                  {data?.titlePopularPages}
                </h2>
                <div className="mt-4 border-t border-b border-gray-medium divide-y divide-gray-medium">
                  {data?.popularPages?.map((link) => (
                    <a key={link.name} href={link.href ?? '/'}>
                      <div className="relative py-6 flex items-start space-x-4 cursor-pointer">
                        <div className="flex-shrink-0">
                          <span className={`flex items-center justify-center h-12 w-12 rounded-lg ${ERROR_ICON_THEME}`}>
                            <HeroIcon icon={link.icon} outline={true} />
                          </span>
                        </div>
                        <div className="min-w-0 flex-1">
                          <h3 className={`${THEME?.headersTextColor?.light} text-b3 font-medium leading-6`}>
                            {link.name}
                          </h3>
                          <p className={`${THEME?.textColor?.light} text-b3 leading-6`}>{link.description}</p>
                        </div>
                        <div className="flex-shrink-0 self-center">
                          <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                      </div>
                    </a>
                  ))}
                </div>
                <div className="mt-8">
                  <a
                    href={`/${localePath}`}
                    className={`${THEME?.highlightTextColor?.light} text-b3 font-medium hover:text-blue-600`}
                  >
                    {data?.goBackText}
                    <span aria-hidden="true"> &rarr;</span>
                  </a>
                </div>
              </div>
            </div>
          </main>
          <div className="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
            <div className="border-t border-gray-medium py-12 text-center md:flex md:justify-between">
              <p className={`${THEME?.footerTextColor?.light} leading-6 text-b3`}>&copy; {data?.copyright}</p>
              <div className="mt-6 flex justify-center space-x-4 md:mt-0">
                {data?.socialMedias?.data?.map((item) => (
                  <a
                    key={item.id}
                    href={item.attributes.href}
                    className={`inline-flex ${THEME?.footerTextColor?.light} hover:text-gray`}
                  >
                    <span className="sr-only">{item.attributes.name}</span>
                    {item.attributes.image ? (
                      <>
                        <NextImage media={item.attributes.image.data} classImg={'object-cover h-6 w-6'} />
                      </>
                    ) : (
                      <HeroIcon icon={item.attributes.icon} outline={true} />
                    )}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export async function getStaticProps() {
  const defaultLocale = await application.getDefaultLocale;
  const locales = await application.getLocales;
  const allPageData = await Promise.all(
    locales.map(async (locale) => await application.getPageData(['error'], locale)),
  );

  return {
    props: {
      pageData: allPageData,
      locales,
      defaultLocale,
    },
  };
}

export default ErrorPage;
